import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap"

import Varlet from '@varlet/ui'
import '@varlet/ui/es/style'
import '@varlet/touch-emulator'

import "./styles/app.scss"

import store from '@/store/index'

createApp(App).use(router).use(Varlet).use(store).mount('#app')

window.Pusher = require('pusher-js')

window.Pusher.logToConsole = true