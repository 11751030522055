import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Landing',
        props: true,
        component: () => import('../views/Landing.vue')
    },
    {
        path: '/pricing',
        name: 'Pricing',
        props: true,
        component: () => import('../views/Pricing.vue')
    },
    {
        path: '/login',
        name: 'Login',
        props: true,
        component: () => import('../views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue')
    },
    {
        path: '/verify',
        name: 'Verification',
        props: true,
        component: () => import('../views/Verification.vue')
    },
    {
        path: '/two_fa',
        name: 'TwoFA',
        props: true,
        component: () => import('../views/TwoFA.vue')
    },
    {
        path: '/recover_two_fa',
        name: 'TwoFARecover',
        props: true,
        component: () => import('../views/TwoFARecover.vue')
    },
    {
        path: '/forgot_password',
        name: 'ForgotPassword',
        props: true,
        component: () => import('../views/ForgotPassword.vue')
    },
    {
        path: '/reset_password',
        name: 'ResetPassword',
        props: true,
        component: () => import('../views/ResetPassword.vue')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        props: true,
        component: () => import('../views/Dashboard.vue')
    },
    {
        path: '/vcs/accounts',
        name: 'VCSAccounts',
        props: true,
        component: () => import('../views/VCSAccounts.vue')
    },
    {
        path: '/projects',
        name: 'Projects',
        props: true,
        component: () => import('../views/Projects.vue')
    },
    {
        path: '/accounts',
        name: 'Accounts',
        props: true,
        component: () => import('../views/Accounts.vue')
    },
    {
        path: '/accounts/:id/repositories',
        name: 'Repositories',
        props: true,
        component: () => import('../views/Repositories.vue')
    },
    {
        path: '/projects/:id',
        name: 'ProjectDetails',
        props: true,
        component: () => import('../views/ProjectDetails.vue')
    },
    {
        path: '/projects/:id/deploy',
        name: 'CreateDeployment',
        props: true,
        component: () => import('../views/CreateDeployment.vue')
    },
    {
        path: '/projects/:id/commands/new',
        name: 'CreateCommand',
        props: true,
        component: () => import('../views/CreateCommand.vue')
    },
    {
        path: '/projects/:project_id/commands/:command_id',
        name: 'UpdateCommand',
        props: true,
        component: () => import('../views/UpdateCommand.vue')
    },
    {
        path: '/projects/:project_id/deployments/:deployment_id',
        name: 'DeploymentDetails',
        props: true,
        component: () => import('../views/DeploymentDetails.vue')
    },
    {
        path: '/projects/:project_id/deployments/:deployment_id/report',
        name: 'DeploymentReport',
        props: true,
        component: () => import('../views/DeploymentReport.vue')
    },
    {
        path: '/projects/:project_id/servers/:server_id',
        name: 'ServerDetails',
        props: true,
        component: () => import('../views/ServerDetails.vue')
    },
    {
        path: '/members',
        name: 'Members',
        props: true,
        component: () => import('../views/Members.vue')
    },
    {
        path: '/payment-methods',
        name: 'PaymentMethods',
        props: true,
        component: () => import('../views/PaymentMethods.vue')
    },
    {
        path: '/plans',
        name: 'Plans',
        props: true,
        component: () => import('../views/Plans.vue')
    },
    {
        path: '/authentication',
        name: 'Authentication',
        props: true,
        component: () => import('../views/Authentication.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        props: true,
        component: () => import('../views/Profile.vue')
    },
    {
        path: '/settings',
        name: 'Settings',
        props: true,
        component: () => import('../views/Settings.vue')
    },
    {
        path: '/members/:id',
        name: 'MemberDetails',
        props: true,
        component: () => import('../views/MemberDetails.vue')
    },
    {
        path: '/members/:hash/invitation',
        name: 'MemberInvitation',
        props: true,
        component: () => import('../views/MemberInvitation.vue')
    },
    {
        path: '/discord/callback',
        name: 'DiscordCallback',
        props: true,
        component: () => import('../views/IntegrationCallback.vue')
    },
    {
        path: '/slack/callback',
        name: 'SlackCallback',
        props: true,
        component: () => import('../views/IntegrationCallback.vue')
    }
]

const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
