<template>
	<div @click="collapse" class="w-100">
		<span class="d-flex align-items-center list-group-item text-muted">
			<img :src="'../assets/icons/' + icon" class="menu-icon">
			<span class="flex-grow-1">{{ text }}</span>
			<img src="../assets/icons/arrows/arr023.svg" :class="'arrow-icon ' + (collapsed ? ' arrow-icon-active' : '')">
		</span>
	</div>

	<router-link v-for="item in items"
	             :class="'list-group-item list-group-item-action text-muted menu-text-font' + (!collapsed ? ' invisible opacity-0' : ' visible opacity-100')"
	             active-class="active"
	             :to="item.to">
		<img :src="item.icon" class="menu-icon ms-4">
		{{ item.text }}
	</router-link>
</template>

<script>
export default {
	name: "Submenu",
	props: {

		icon: {
			type: String,
			required: true
		},
		text: {
			type: String,
			required: true
		},
		items: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			collapsed: false
		}
	},
	methods: {
		collapse() {
			this.collapsed = !this.collapsed
		}
	}
}
</script>

<style scoped>

.list-group-item {
	transition: visibility 0s, opacity .3s ease-in-out;
	cursor: pointer;
	flex: 0 0 100%;
}

.arrow-icon {
	display: flex;
	align-items: stretch;
	position: relative;
	flex-shrink: 0;
	margin-left: .5rem;
	height: 14px;
	transition: transform .3s;
	filter: invert(32%) sepia(3%) saturate(7273%) hue-rotate(200deg) brightness(85%) contrast(86%);
}

.list-group-item:hover > .arrow-icon {
	filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(173deg) brightness(112%) contrast(105%);
}

.arrow-icon-active {
	transform: rotate(90deg);
	-webkit-transform:rotate(90deg);
}

.invisible {
	display: none;
}

.visible {
	display: block;
}

</style>