<template>
	<nav class="navbar navbar-expand-sm navbar-light bg-transparent">
		<div class="container-fluid">
			<a class="navbar-brand" href="/">
				<img src="@/assets/logo.png" alt="Logo" width="150" height="150">
			</a>
			<button 
				class="navbar-toggler" 
				type="button" 
				data-bs-toggle="collapse" 
				data-bs-target="#navigationBar" 
				aria-controls="navigationBar" 
				aria-expanded="false" 
				aria-label="Toggle navigation"
			>
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navigationBar">
				<div class="navbar-nav ms-auto">
					<router-link class="nav-link ms-xl-3 me-xl-3 ms-lg-2 me-lg-2 ms-md-1 me-md-1 ms-sm-1 me-sm-1"
					             active-class="active"
					             data-toggle="collapse"
					             data-target=".navbar-collapse.show"
					             :to="{name: 'Landing'}" exact>
						Welcome
					</router-link>
          <router-link class="nav-link ms-xl-3 me-xl-3 ms-lg-2 me-lg-2 ms-md-1 me-md-1 ms-sm-1 me-sm-1"
                       active-class="active"
                       data-toggle="collapse"
                       data-target=".navbar-collapse.show"
                       :to="{name: 'Dashboard'}" exact>
            Dashboard
          </router-link>
          <router-link class="nav-link ms-xl-3 me-xl-3 ms-lg-2 me-lg-2 ms-md-1 me-md-1 ms-sm-1 me-sm-1"
                       active-class="active"
                       data-toggle="collapse"
                       data-target=".navbar-collapse.show"
                       :to="{name: 'Register'}" exact>
            Register
          </router-link>
          <router-link class="nav-link ms-xl-3 me-xl-3 ms-lg-2 me-lg-2 ms-md-1 me-md-1 ms-sm-1 me-sm-1"
                       active-class="active"
                       data-toggle="collapse"
                       data-target=".navbar-collapse.show"
                       :to="{name: 'Login'}" exact>
            Login
          </router-link>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name: "Navigation"
}
</script>

<style lang="scss" scoped>

@import "@/styles/app.scss";

html, body {
	font-family: Orbitron, sans-serif;
}

.nav-link {
	text-align: right;
	color: $landing-icon !important;
}

.nav-link.active {
	font-weight: bolder;
	text-decoration: underline;
	text-underline-offset: 3px;
}

.nav-link:hover {
	font-weight: bolder;
}

</style>