<template>
	<div v-if="[
		'Login',
		'Register',
		'Verification',
		'TwoFA',
		'TwoFARecover',
		'ForgotPassword',
		'ResetPassword',
		'MemberInvitation',
		'DiscordCallback',
		'SlackCallback'
	].includes($route.name)" class="container auth-container">
		<div class="row h-100">
			<div class="col-12">
				<router-view/>
			</div>
		</div>
	</div>

	<div v-else-if="[
		'Landing'
	].includes($route.name)" class="container-fluid min-vh-100 d-flex flex-column landing">
		<div class="row">
			<div class="col-12">
				<navigation />
			</div>
		</div>

		<div class="row flex-grow-1">
			<div class="col-12">
				<router-view/>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<nav-footer />
			</div>
		</div>
	</div>

	<div v-else>
		<!-- Hamburger Menu -->
		<hamburger-menu @onCollapse="onMenuCollapse" />

		<!-- Content -->
		<div :style="contentStyle" class="bg-main">
			<div class="container p-4 h-100 w-100">
				<div class="row m-0 h-100 w-100">
					<div class="col-12">
						<router-view v-slot="{ Component, route }">
							<transition name="fade" mode="out-in">
								<div :key="route.name">
									<component :is="Component" />
								</div>
							</transition>
						</router-view>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import HamburgerMenu from "./components/HamburgerMenu";
import request from "./request/index"
import Navigation from "./components/Navigation";
import NavFooter from "./views/Footer";

export default {
	components: {NavFooter, Navigation, HamburgerMenu},
	created() {
		document.getElementById('app').classList.add('theme--light')
	},
	watch: {
		$route(to, from) {

			if (![
				'Login',
				'Register',
				'Verification',
				'TwoFA',
				'TwoFARecover',
				'ForgotPassword',
				'ResetPassword',
				'MemberInvitation',
				'DiscordCallback',
				'SlackCallback',
				'Landing',
			].includes(to.name)) {
				this.loggedIn = localStorage.getItem('token') !== null || this.$route.query.hash != null

				if (!this.loggedIn) {
					this.$router.push({name: 'Login'})
				}
			}
		}
	},
	computed: {
		contentStyle() {
			let width = this.collapsed ? 73 : 290;

			return `width: calc(100% - ${width}px); `
		}
	},
	data() {
		return {
			loggedIn: false,
			interval: null,
			collapsed: !!this.$store.state.user?.sidebar_collapsed
		}
	},
	methods: {
		onMenuCollapse(collapsed) {
			this.collapsed = collapsed

			this.$store.dispatch('setSidebarStyle', {
				sidebar_collapsed: this.collapsed
			})
		}
	}
}
</script>

<style>

html, body {
	margin: 0;
	padding: 0;
	font-size: 13px;
	transition: background-color .25s;
	color: var(--color-text) !important;
	background-color: var(--color-body) !important;
}

.bg-main {
	float: right;
	height: 100%;
}

.page-content {
	overflow-y: scroll;
	height: 100%
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}


.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

</style>