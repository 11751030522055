<template>
	<sidebar-menu :collapsed="sidebarCollapsed" :menu="menu" @update:collapsed="$emit('onCollapse', $event)"
	              @item-click="logout">
		<template v-slot:footer>
			<var-switch @click="toggleTheme" v-model="isDark" :loading="loading"/>
		</template>
	</sidebar-menu>
</template>

<script>
import Submenu from "./Submenu"
import dark from "@varlet/ui/es/themes/dark"
import {StyleProvider} from "@varlet/ui"
import {SidebarMenu} from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

export default {
	name: "HamburgerMenu",
	components: {
		Submenu,
		SidebarMenu
	},
	emits: ['onCollapse'],
	created() {
		StyleProvider(this.isDark ? dark : null)

		this.menu = [
			{
				header: 'Main',
				hiddenOnCollapse: true
			},
			{
				href: '/dashboard',
				title: 'Dashboard',
				icon: {
					element: 'i',
					class: 'bi bi-graph-up'
				}
			},
			{
				href: '/projects',
				title: 'Projects',
				icon: {
					element: 'i',
					class: 'var-icon var-icon--set var-icon-notebook'
				}
			}
		]

		if (this.$store.getters.canConnectAccounts) {
			this.menu.push({
				href: '/accounts',
				title: 'Linked Accounts',
				icon: {
					element: 'i',
					class: 'var-icon var-icon--set var-icon-github'
				}
			})
		}

		this.menu.push({
			header: 'Account',
			hiddenOnCollapse: true
		})
		this.menu.push({
			href: '/settings',
			title: 'Settings',
			icon: {
				element: 'i',
				class: 'var-icon var-icon--set var-icon-wrench'
			}
		})
		this.menu.push({
			href: '/profile',
			title: 'Profile',
			icon: {
				element: 'i',
				class: 'var-icon var-icon--set var-icon-account-circle'
			}
		})
		this.menu.push({
			href: '/authentication',
			title: 'Authentication',
			icon: {
				element: 'i',
				class: 'var-icon var-icon--set var-icon-qrcode-scan'
			}
		})

		if (this.$store.getters.isOwner) {
			this.menu.push({
				href: '/members',
				title: 'Members',
				icon: {
					element: 'i',
					class: 'bi bi-people-fill'
				}
			})
		}

		if (this.$store.getters.isOwner) {
			this.menu.push({
				header: 'Billing',
				hiddenOnCollapse: true
			})

			this.menu.push({
				href: '/payment-methods',
				title: 'Payment Methods',
				icon: {
					element: 'i',
					class: 'bi bi-credit-card-2-back'
				}
			})

			this.menu.push({
				href: '/plans',
				title: 'Plans and Usage',
				icon: {
					element: 'i',
					class: 'bi bi-box-seam'
				}
			})
		}

		this.menu.push({
			title: 'Logout',
			icon: {
				element: 'i',
				class: 'var-icon var-icon--set var-icon-power'
			}
		})
	},
	data() {
		return {
			loading: false,
			sidebarCollapsed: !!this.$store.state.user.sidebar_collapsed,
			isDark: !!this.$store.state.user.dark_mode,
			currentTheme: this.$store.state.user.dark_mode ? dark : null,
			menu: []
		}
	},
	methods: {
		toggleTheme() {
			this.isDark = !this.isDark

			this.$store.dispatch('setDarkMode', {
				dark_mode: this.isDark,
				loading: this.loading
			})
			StyleProvider(this.isDark ? dark : null)
		},
		logout(event, item) {
			if (item.title === 'Logout') {
				this.$store.commit('setUser', {
					id: null,
					organization_id: null,
					email: null,
					username: null,
					provider: null,
					two_fa_enabled: null,
					dark_mode: null,
					sidebar_collapsed: null,
					token: null,
					role: null,
					can_create_projects: null,
					can_connect_accounts: null
				})
				localStorage.removeItem('token')
				this.$router.push({name: 'Login'})
			}
		}
	}
}
</script>

<style scoped>

</style>