<template>
	<div class="container-fluid bg-dark">
		<div class="row p-4">
			<div class="col-xl-1 col-lg-1 col-md-1 col-sm-4 col-4 text-center">
				<a :href="statics.facebook" target="_blank">
					<img src="/img/github.png" class="social-icon" alt="Facebook">
				</a>
			</div>
			<div class="col-xl-1 col-lg-1 col-md-1 col-sm-4 col-4 text-center">
				<a :href="statics.twitter" target="_blank">
					<img src="/img/stackoverflow.svg" class="social-icon" alt="Twitter">
				</a>
			</div>
			<div class="col-xl-1 col-lg-1 col-md-1 col-sm-4 col-4 text-center">
				<a :href="statics.linkedin" target="_blank">
					<img src="/img/linkedin.png" class="social-icon" alt="Linkedin">
				</a>
			</div>
			<div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
				<div class="row justify-content-center pt-4 pt-md-0">
					<router-link class="nav-link"
					             active-class="active"
					             to="/" exact>
						WELCOME
					</router-link>
					<router-link class="nav-link"
					             active-class="active"
					             to="/a" exact>
						HOW IT WORKS
					</router-link>
					<router-link class="nav-link"
					             active-class="active"
					             to="/features" exact>
						FEATURES
					</router-link>
					<router-link class="nav-link"
					             active-class="active"
					             :to="{ name: 'Pricing'}" exact>
						PRICING
					</router-link>
					<router-link class="nav-link"
					             active-class="active"
					             to="/terms" exact>
						TERMS AND CONDITIONS
					</router-link>
					<router-link class="nav-link"
					             active-class="active"
					             to="/privacy" exact>
						PRIVACY POLICY
					</router-link>
				</div>
			</div>
		</div>

		<div class="row p-0">
			<div class="col-12 text-left">
				<small>&#169; Copyright {{ new Date().getFullYear() }}. Gevorg Melkumyan</small>
			</div>
		</div>
	</div>
</template>

<script>
import statics from '@/static/index'

export default {
	name: "NavFooter",
	data () {
		return {
			statics
		}
	}
}
</script>

<style lang="scss" scoped>

@import "@/styles/app.scss";

.bg-dark {
	background-color: $landing-icon !important;
}

.nav-link {
	color: $landing-background !important;
}

.nav-link.active {
	font-weight: bolder;
	text-decoration: underline;
	text-underline-offset: 3px;
}

.nav-link:hover {
	font-weight: bolder;
}

</style>