import axios from 'axios'
import router from "../router";

export default {
    get: (url) => new Promise((resolve, reject) => {
       axios.get(url, {
           headers: {
               'Authorization': 'Bearer ' + localStorage.getItem('token')
           }
       })
           .then(response => {
               resolve(response.data)
           })
           .catch(error => {
               if (error.response.status == 401) {
                   localStorage.removeItem('token')
                   router.push({
                       name: 'Login'
                   })
               }

               reject(error)
           })
    }),

    post: (url, data) => new Promise((resolve, reject) => {
        axios.post(url, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.status == 401) {
                    localStorage.removeItem('token')
                    router.push({name: 'Login'})
                }

                reject(error)
            })
    }),

    put: (url, data) => new Promise((resolve, reject) => {
        axios.put(url, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.status == 401) {
                    localStorage.removeItem('token')
                    router.push({name: 'Login'})
                }

                reject(error)
            })
    }),

    patch: (url, data) => new Promise((resolve, reject) => {
        axios.patch(url, data, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.status == 401) {
                    localStorage.removeItem('token')
                    router.push({name: 'Login'})
                }

                reject(error)
            })
    }),

    delete: (url) => new Promise((resolve, reject) => {
        axios.delete(url, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    router.push({name: 'Login'})
                }

                reject(error)
            })
    })
}