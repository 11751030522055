import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import request from '@/request/index'

export default createStore({
    plugins: [createPersistedState({
        storage: window.localStorage
    })],
    state: {
        user: {
            id: null,
            organization_id: null,
            email: null,
            username: null,
            provider: null,
            two_fa_enabled: null,
            dark_mode: null,
            sidebar_collapsed: null,
            token: null,
            role: null,
            can_create_projects: null,
            can_connect_accounts: null
        }
    },
    getters: {
        organizationId(state) {
            return state.user.organization_id
        },
        userId(state) {
            return state.user.id
        },
        role(state) {
            return state.user.role
        },
        isOwner(state) {
            return state.user.role === 'owner'
        },
        isMember(state) {
            return state.user.role === 'member'
        },
        canCreateProjects(state) {
            return state.user.can_create_projects
        },
        canConnectAccounts(state) {
            return state.user.can_connect_accounts
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setDarkMode(state, payload) {
            state.user.dark_mode = payload.dark_mode
        },
        setSidebarCollapsed(state, payload) {
            state.user.sidebar_collapsed = payload.sidebar_collapsed
        }
    },
    actions: {
        setDarkMode(context, payload) {
            request.put(process.env.VUE_APP_BASE_URL + '/users/' + context.state.user.id, payload)
                .then(data => {
                    context.commit('setDarkMode', payload)
                })
                .catch(error => {
                    alert(error.response.data.message)
                })
        },
        setSidebarStyle(context, payload) {
            request.put(process.env.VUE_APP_BASE_URL + '/users/' + context.state.user.id, payload)
                .then(data => {
                    context.commit('setSidebarCollapsed', payload)
                })
                .catch(error => {
                    alert(error.response.data.message)
                })
        }
    }
})